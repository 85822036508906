$color-main: #1b2431;
$color-main-secondary: #273142;
$color-footer: #2c384b;
$color-blue: #00aeef;
$color-gray: gray;
$color-white: #fff;
$color-text: #fff;
$color-black: black;
$color-red: #b32424;
$color-prio-red: #cf473e;
$color-error-red: #ed1c24;
$color-text-body: #eaedf3;
$color-white-secondary: #fafafa;

// FONT SIZES
$font-size-smallest: var(--font-size-smallest);
$font-size-smaller: var(--font-size-smaller);
$font-size-small: var(--font-size-small);
$font-size-normal: var(--font-size-normal);
$font-size-medium: var(--font-size-medium);
$font-size-large: var(--font-size-large);
$font-size-larger: var(--font-size-larger);

$width-title-bullet: var(--width-title-bullet);
