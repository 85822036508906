html,
body {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif;
  height: 100%;
  color: #fff;
  background-color: #1b2431;
}

:root {
  --height-footer: calc((195 / 1080) * 100vh);
  --width-title-bullet: calc((53 / 1080) * 100vh);
  --font-size-smallest: calc((24 / 1080) * 100vh);
  --font-size-smaller: calc((30 / 1080) * 100vh);
  --font-size-small: calc((36 / 1080) * 100vh);
  --font-size-normal: calc((40 / 1080) * 100vh);
  --font-size-medium: calc((48 / 1080) * 100vh);
  --font-size-large: calc((50 / 1080) * 100vh);
  --font-size-larger: calc((80 / 1080) * 100vh);
  --title-bullet: grey;
}

@media screen and (min-width: 1920px) and (max-width: 1920px) and (min-height: 1080px) and (max-height: 1080px) {
  :root {
    --height-footer: 195px;
    --width-title-bullet: 53px;
    --font-size-smallest: 24px;
    --font-size: 30px;
    --font-size-small: 36px;
    --font-size-normal: 40px;
    --font-size-medium: 48px;
    --font-size-large: 50px;
    --font-size-larger: 80px;
  }
}